@import url('https://fonts.googleapis.com/css?family=PT+Serif+Caption:regular,bold,italic&subset=latin,latin-ext');

@media only screen and (max-width: 768px) {

  body {
    margin: 0;
    font-family: "PT Serif Caption", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .parent {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }

  .center {
    margin: auto;
    text-align: center;
    overflow:auto;
  }

  h1 {
    padding-bottom: 2vw;
    font-size: 12vw;
  }

  .above-line-text {
    color: #757474;
  }

  .link-parent {
    display: inline-flex;
    justify-content: center;
    width: 100%;
  }

  .link-child {
    margin: 1vw;
    color: #757474;
    text-decoration: none;
    font-size: 3.75vw;
  }

  .link-child:hover {
    transition: color 0.45s ease-in-out;
    color: #9ED29D;
  }

  .about-text {
    margin-left: auto;
    margin-right: auto;
    color: #757474;
    word-wrap: break-word;
    width: 80vw;
    text-align: center;
    font-size: 4vw;
  }

  a.about-text: {
    color: #757474;
    text-decoration: none;
    transition: color 0.45s ease-in-out;
  }

  a.about-text:hover {
    transition: color 0.45s ease-in-out;
    color: #9ED29D;
  }

  #line {
    border: 0.75px solid #9ED29D;
    width: 90vw;
  }

  #name-text {
    color: #9ED29D;
  }
}

@media only screen and (min-width: 768px) {

  body {
    margin: 0;
    font-family: "PT Serif Caption", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .center {
    text-align: center;
    width: 100vw;
    height: 90vh;

    position:absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin:auto;

    overflow:auto;
  }

  h1 {
    padding-bottom: 2vw;
    font-size: 4vw;
  }

  .above-line-text {
    color: #757474;
    font-size: 1.15vw;
  }

  .link-parent {
    display: inline-flex;
    justify-content: center;
    width: 100%;
  }

  .link-child {
    margin: 1vw;
    color: #757474;
    text-decoration: none;
    transition: color 0.45s ease-in-out;
  }

  .link-child:hover {
    transition: color 0.45s ease-in-out;
    color: #9ED29D;
  }

  .about-text {
    margin-left: auto;
    margin-right: auto;
    color: #757474;
    word-wrap: break-word;
    width: 40vw;
    text-align: center;
    transition: color 0.45s ease-in-out;
  }

  a.about-text: {
    color: #757474;
    text-decoration: none;
  }

  a.about-text:hover {
    transition: color 0.45s ease-in-out;
    color: #9ED29D;
  }

  #line {
    border: 0.75px solid #9ED29D;
    width: 50vw;
  }

  #name-text {
    color: #9ED29D;
  }
}
